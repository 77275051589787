import React, { useContext } from 'react';

import { SearchBarContainer } from '../styles/HeroSearch.styled';
import { FaqSearchContext } from '../../../contexts/faqSearchContext';
import Search from '../../../molecules/Inputs/Search/Search';

const HeroSearch = () => {
  const { faqSearchState, faqSearchDispatch } = useContext(FaqSearchContext);

  return (
    <>
      {faqSearchState.search && (
        <p>
          Displaying {faqSearchState.count} results
          {faqSearchState.search && ` for "${faqSearchState.search}"`}
        </p>
      )}
      <SearchBarContainer>
        <Search
          searchValue={faqSearchState.search}
          setSearchValue={faqSearchDispatch}
          placeholder="Find anything (ex. features, pricing, or integrations)"
        />
      </SearchBarContainer>
    </>
  );
};

export default HeroSearch;
